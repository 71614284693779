<template>
  <div class="vx-row mb-12">
    <div class="vx-row mb-12" style="width:100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Sales Order</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="salesOrder" disabled />
      </div>
    </div>
    <div class="vx-row mb-12" style="width:100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button
          v-if="!edit"
          color="warning"
          v-on:click="editData"
          icon-pack="feather"
          icon="icon-edit"
        >Edit</vs-button>
        <vs-button
          v-else
          color="success"
          v-on:click="save"
          icon-pack="feather"
          icon="icon-save"
        >Save</vs-button>
      </div>
    </div>

    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
     <template slot="tbody">
       <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.salesOrderLine">
          <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
          <vs-td style="align-item-center">{{tr.ItemName}}({{tr.ItemCode}})</vs-td>
          <vs-td>
            <vs-tr :key="indexsub" v-for="(subtr,indexsub) in tr.Request.split(',')">
                <vs-td>{{subtr}}</vs-td>
            </vs-tr>
          </vs-td>
          <vs-td>
            <vs-tr :key="indexsub" v-for="(subtr,indexsub) in tr.Quantity.split(',')">
            <template v-if="!edit">
                  <vs-td>{{subtr}} ({{tr.ItemUnit.split(',')[indexsub]}})</vs-td>
            </template>
            <template v-else>
                <vue-number-input
                  :min="0"
                  :max="parseInt(subtr)"
                  :step="1"
                  size="small"
                  inline
                  controls
                  v-model="salesOrderEdit[indextr][indexsub][1]"
                />{{tr.ItemUnit.split(',')[indexsub]}}
            </template>
            </vs-tr>
          </vs-td>
        </vs-tr>
     </template>
     </data-table>
    <!-- TABLE ACTION ROW -->
    
  </div>
</template>
<script>import VueNumberInput from "@chenfengyuan/vue-number-input";
export default {
  components: {
    VueNumberInput
  },
  props: ["dataId", "salesOrder","deliveryPlan", "edit"],
  mounted() {
    this.reloadData(this.params);

  },
  watch: {
    dataId() {
      this.reloadData(this.params);
      // console.log('The dataId has changed!')
    }
  },
  computed: {
    
  },
  data: () => ({
    salesOrderEdit: [],
    params: {
      search: "",
      length: 10,
      page: 1,
      order: "asc",
      sort: "item_name"
    },
    header: [
      {
        text: "SKU Name",
        value: 'item_name'
      },
      {
        text: "Quantity Request",
        // value: "name"
      },
      {
        text: "Quantity Reserve",
        // value: "name"
      },
    ],
    responseData: {},
    detailShow: "core vx-col md:w-1/2 w-full mb-base",
    detailHide: "core vx-col md:w-1/1 w-full mb-base",
    detail: false,
    data: [{ id: 1 }]
  }),
  methods: {
    reloadData(params) {
      console.log("sip")
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/sales-order/lines", {
          params: {
            adjust:'adjust',
            sales_order_id: this.dataId,
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort
          }
        })
        .then(
          resp => {
            console.log(resp)
            console.log("resp")
            if(resp.code == 200){
              this.responseData = resp.data;
            }
            this.$vs.loading.close();
          }
        );
    },
    editData() {
      var salesOrder = [];
      // console.log("asd")
      // var temp = 0;
      this.data = this.responseData.salesOrderLine
      for (var i = 0; i < this.data.length; i++) {
        var temp = []
        var adjust = this.data[i].Quantity.split(",");
        var uom = this.data[i].AmountUom.split(",");
        var splitId = this.data[i].StringID.split(",");
        for (var prop in adjust) {
          console.log(adjust[prop])
          console.log("adjust[prop]")
          temp.push([parseInt(splitId[prop]), parseInt(adjust[prop]), parseInt(uom[prop]) / parseInt(adjust[prop]), this.data[i].ItemCode]);
        }
        salesOrder.push(temp)
      }
      this.salesOrderEdit = salesOrder;
      this.edit = true;
    },
    save() {
      this.$vs.loading();
      var send = []
      for (var prop in this.salesOrderEdit) {
          for (var j = 0; j < this.salesOrderEdit[prop].length; j++) {
            console.log(this.salesOrderEdit[prop][j])
            var person = {id:this.salesOrderEdit[prop][j][0], quantity:this.salesOrderEdit[prop][j][1],general:this.salesOrderEdit[prop][j][2], item_code:this.salesOrderEdit[prop][j][3]};
            send.push(person);
          }
        }
      this.$http
      .post("api/v1/allocation-engine/list/delivery-plan/draft/sales-order/update", {
        adjust: send,
        balance: 1,
        delivery_plan: this.deliveryPlan,
        item_code: "",
      })  
      .then(resp => {
        this.$vs.notify({
          title: "success",
          text: "Success",
          color: "success",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check"
        });
        console.log(resp);
        this.reloadData(this.params)
        this.$vs.loading.close();
        // this.$emit("refresh","")
      });
      this.edit = false;
    },
    pageDetail(val) {
      // this.getData(val);
      return val;
    },
    changeLimit(val) {
      this.limitShow = val;
      this.getData(this.isActive);
    },
    getData(val = 1) {
      this.isActive = val;
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/sales-order/lines", {
          params: {
            adjust: "adjust",
            salesOrderID: this.dataId,
            length: this.limitShow,
            search: this.search,
            draw: this.drawData,
            start: this.limitShow * (val - 1)
          }
        })
        .then(resp => {
          this.drawData = this.drawData + 1;
          this.data = resp.data.salesOrderLine;
          this.recordsTotal = resp.data.recordsTotal;
          this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
          this.dataEnd = resp.data.salesOrderLine.length;
          this.$vs.loading.close();
          // }
        });
    }
  }
};
</script>