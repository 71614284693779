<template>
  <vx-card title="Delivery Plan">
    <vs-collapse class="ml-0 pl-0 mb-6" style="border: 1px solid #d0d0d0">
      <vs-collapse-item class="ml-0 pl-0">
        <div slot="header" class="ml-0 pl-0">Import Excel</div>
        <!-- <vx-card class="mb-6"> -->
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="territorySelected"
              :options="territoryData"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              track-by="code"
              label="name"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.code }} {{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.code }} {{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Customer</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="customerSelected"
              :options="customerData"
              :multiple="true"
              :allow-empty="true"
              :group-select="false"
              :max-height="100"
              :limit="10"
              placeholder="Type to search"
              track-by="code"
              label="name"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.code }} {{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.code }} {{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Sales Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="salesChannel.selected"
              :options="salesChannel.options"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              track-by="id"
              label="name"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.code }} {{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.code }} {{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>File</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <input
              class="inputx"
              type="file"
              v-if="uploadReady"
              ref="file"
              name="file"
              accept=".xls, .xlsx"
            />
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button class="ml-auto mt-2" v-on:click="handleSubmit"
              >Submit</vs-button
            >
            <vs-button
              class="ml-4 mt-2"
              type="border"
              color="warning"
              v-on:click="handleDownload"
              >Download Template</vs-button
            >
          </div>
        </div>
        <!-- </vx-card> -->
      </vs-collapse-item>
    </vs-collapse>
    <div class="vx-row mb-6" style="width: 50%">
      <div
        class="vx-col sm:w-1/3 w-full flex items-center"
        style="margin-bottom: 30px"
      >
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="territory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          track-by="id"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
      <div
        class="vx-col sm:w-1/3 w-full flex items-center"
        style="margin-bottom: 30px"
      >
        <span>Sales Channel</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="salesChannel2"
          :options="optionSalesChannel"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          track-by="id"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
      <div
        class="vx-col sm:w-1/3 w-full flex items-center"
        style="margin-bottom: 30px"
      >
        <span>Distribution Channel</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="customerCategory"
          :options="optionCustomerCategory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          track-by="id"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
      <div
        class="vx-col sm:w-1/3 w-full flex items-center"
        style="margin-bottom: 30px"
      >
        <span>Start Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <flat-pickr :config="configFlatPickr" v-model="startDate"></flat-pickr>
      </div>
      <div
        class="vx-col sm:w-1/3 w-full flex items-center"
        style="margin-bottom: 30px"
      >
        <span>End Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <flat-pickr :config="configFlatPickr" v-model="endDate"></flat-pickr>
      </div>
    </div>
    <vs-tabs :color="colorx" v-model="tabs">
      <vs-tab @click="colorx = 'danger'" label="Open">
        <div class="con-tab-ejemplo">
          <open
            v-if="renderComponent"
            :territory="territory"
            :customer-category="customerCategory"
            :sales-channel="salesChannel2"
            :start-date="startDate"
            :end-date="endDate"
          ></open>
        </div>
      </vs-tab>
      <vs-tab @click:colorx="colorx = 'warning'" label="Draft">
        <div class="con-tab-ejemplo">
          <draft :territory="territory"></draft>
        </div>
      </vs-tab>
      <vs-tab @click="colorx = 'warning'" label="Release">
        <div class="con-tab-ejemplo">
          <release :territory="territory"></release>
        </div>
      </vs-tab>
      <vs-tab @click="colorx = 'success'" label="Complete">
        <div class="con-tab-ejemplo">
          <complete :territory="territory"></complete>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import open from "./open/open.vue";
import draft from "./draftIndex.vue";
import release from "./release/release.vue";
import complete from "./complete/complete.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import vSelect from "vue-select";

export default {
  components: {
    open,
    draft,
    release,
    complete,
    flatPickr,
    vSelect,
  },
  // async mounted() {
  //   await this.showTerritory();
  // },
  data() {
    return {
      renderComponent: true,
      territoryData: [{ ID: "", Name: "" }],
      customerData: [{ ID: "", Name: "" }],
      territorySelected: null,
      customerSelected: null,
      uploadReady: true,
      salesChannel: {
        options: [],
        selected: null,
      },
      salesChannelId: null,

      customerCategory: {},
      optionCustomerCategory: [{ ID: "", Name: "" }],
      territory: {},
      optionTerritory: [{ ID: "", Name: "" }],
      salesChannel2: {}, //{ text: "Malang Raya", value: 1 },
      optionSalesChannel: [{ ID: "", Name: "" }],
      configFlatPickr: {
        // wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "F j, Y",
        // altInput: true,
        dateFormat: "j F Y",
        enableTime: true,
        minTime: "00:00",
        plugins: [new ConfirmDatePlugin()],
      },
      startDate: "",
      endDate: "",
      colorx: "danger",
      items: [],
      // deliveryPlans: [],
      // numberTabs:[],
      tabs: 0,
    };
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    handleDownload() {
      if (this.territorySelected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else if (
        this.customerSelected == null ||
        this.customerSelected.length == 0
      ) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "Customer is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.$vs.loading();
        var a = this.customerSelected.map((cust) => cust.code);
        var str = a.join(",");
        this.$http
          .get("/api/v1/sales-order/excel-with-price", {
            params: {
              territory_code: this.territorySelected.code,
              customer_code: str,
            },
            responseType: "arraybuffer",
            headers: {
              Accept: "application/octet-stream",
            },
          })
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.status == "error") {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "sales-order-template.xlsx");
            document.body.appendChild(fileLink);
            fileLink.click();
          });
      }
    },
    handleSubmit() {
      var file = this.$refs.file.files[0];
      if (this.territorySelected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else if (this.salesChannelId == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "Sales Channel is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else if (file == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "File is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.formData = new FormData();
        this.formData.append("territory_code", this.territorySelected.code);
        this.formData.append("sales_channel_id", this.salesChannelId);
        this.formData.append("file", file);
        this.$http
          .post("/api/v1/sales-order/excel-to-allocation", this.formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              var message = "";
              resp.data.map(function (item, index) {
                var total = resp.data.length;
                if (index == total - 1) {
                  message += item.sales_order_code;
                } else {
                  message += item.sales_order_code + ", ";
                }
              });
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.uploadReady = false;
              this.$nextTick(() => {
                this.uploadReady = true;
              });
              this.forceRerender()
              this.customerSelected = null;
              this.salesChannel.selected = null;

            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: error,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          });
      }
    },
    getAllDataSalesChannel() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/sales-channel", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.salesChannel.options = resp.data.records;
            // if (this.salesChannel.options.length > 0) {
            //   this.salesChannel.selected = this.salesChannel.options[0];
            // }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },

    // -----
    getCustomerData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/list/customer", {
          params: {
            territory_id: this.territorySelected.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.customerData = [];
            for (var cust = 0; cust < resp.data.records.length; cust++) {
              this.customerData.push({
                id: resp.data.records[cust].id,
                name: resp.data.records[cust].name,
                code: resp.data.records[cust].code,
              });
            }
            // this.customerData = resp.data.records;
          } else {
            console.log("error get customer");
          }
        });
    },
    showTerritory() {
      this.$http
        .get("/api/v1/allocation-engine/list/territory")
        .then((resp) => {
          if (resp.code == 200) {
            this.territoryData = [];
            this.optionTerritory = [];
            this.optionTerritory.push({ name: "All" });
            for (
              var territory = 0;
              territory < resp.data.territory.length;
              territory++
            ) {
              this.optionTerritory.push(resp.data.territory[territory]);
              this.territoryData.push(resp.data.territory[territory]);
            }
            this.territory = this.optionTerritory[0];
            this.territorySelected = this.territoryData[0];

            this.getCustomerData();
          } else {
            this.$router.push("/");
          }
        });
      this.$http.get("/api/v1/sales-channel/list").then((resp) => {
        if (resp.code == 200) {
          this.optionSalesChannel = [];
          this.optionSalesChannel.push({ name: "All" });
          for (
            var salesChannel = 0;
            salesChannel < resp.data.length;
            salesChannel++
          ) {
            this.optionSalesChannel.push(resp.data[salesChannel]);
          }
          this.salesChannel2 = this.optionSalesChannel[0];
        } else {
          this.$router.push("/");
        }
      });
      this.$http.get("/api/v1/master/customer-category-all").then((resp) => {
        if (resp.code == 200) {
          this.optionCustomerCategory = [];
          this.optionCustomerCategory.push({ name: "All" });
          for (
            var customerCategory = 0;
            customerCategory < resp.data.length;
            customerCategory++
          ) {
            this.optionCustomerCategory.push(resp.data[customerCategory]);
          }
          this.customerCategory = this.optionCustomerCategory[0];
        } else {
          this.$router.push("/");
        }
      });
    },
    changeTabs(index, indexTab) {
      this.numberTabs[index] = indexTab;
    },
    hideItem(index) {
      this.deliveryPlans[index].tab = false;
      this.deliveryPlans[index].disallow = false;
      this.numberTabs[index] = 0;
    },
    hideDisallow(index) {
      this.deliveryPlans[index].disallow = false;
      this.numberTabs[index] = 0;
    },
    approveDelivery(deliveryPlanId) {
      this.$vs.loading();
      var bodyFormData = new FormData();
      bodyFormData.set("DeliveryPlanID", deliveryPlanId);
      bodyFormData.set("status", 1);
      this.$http
        .put(
          "api/v1/allocation-engine/list/delivery-plan/store/status",
          bodyFormData
        )
        .then((resp) => {
          if (resp.code) this.$vs.loading.close();
          this.getDP();
        });
    },
    getDP() {
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/draft", {
          params: {
            status: 0,
            territory: this.territory.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.deliveryPlans = resp.data.deliveryPlan;
            console.log(resp.data.deliveryPlan);
            console.log("resp.data.deliveryPlan");
            if (resp.data.deliveryPlan.length != 0) {
              for (var i = 0; i <= resp.data.deliveryPlan.length; i++) {
                this.deliveryPlans[i].tab = true;
                this.deliveryPlans[i].disallow = true;
                this.numberTabs[i] = 1;
                console.log("masuk sini");
              }
            }
          }
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    this.showTerritory();
    this.getAllDataSalesChannel();
  },
  watch: {
    "salesChannel.selected": function (v) {
      this.salesChannelId = v.id;
    },
  },
};
</script>
<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
.divider-bottom {
  border-bottom: 1px solid #6c6cec;
  /* min-height:800px; */
  padding: 5px;
}
</style>