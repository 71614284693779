<template>
  <div class="vx-row mb-12">
    <div class="vx-row mb-12" style="width:100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Item Name</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="dataItem" disabled />
      </div>
    </div>
    <div class="vx-row mb-12" style="width:100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button
          v-if="!edit"
          color="warning"
          v-on:click="editData"
          icon-pack="feather"
          icon="icon-edit"
        >Edit</vs-button>
        <template v-else>
          <vs-button
            style="margin-bottom:10px"
            color="success"
            v-on:click="save"
            icon-pack="feather"
            icon="icon-save"
          >Save</vs-button>
          <vs-chip v-if="available>=0" color="success">
            <vs-avatar icon="check" />
            balance : {{available}} (UOM Lv 1)
          </vs-chip>
          <vs-chip v-else color="danger">
            <vs-avatar icon="clear" />
            balance : {{available}} (UOM Lv 1)
          </vs-chip>
        </template>
      </div>
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
     <template slot="tbody">
       <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.items">
          <vs-td style="align-item-center">{{tr.Code}}</vs-td>
          <vs-td style="align-item-center">{{tr.CustomerName}}</vs-td>
          <vs-tr :key="indexsub" v-for="(subtr,indexsub) in tr.Request.split(',')">
            <vs-td>{{subtr}}</vs-td>
          </vs-tr>
          <vs-td>
            <vs-tr :key="indexsub" v-for="(subtr,indexsub) in tr.Quantity.split(',')">
              <template v-if="!edit">
                <vs-td>{{subtr}} ({{tr.ItemUnit.split(',')[indexsub]}})</vs-td>
              </template>
              <template v-else>
                <vue-number-input
                  inline
                  controls
                  :min="0"
                  :step="1"
                  size="small"
                  @change="test(indextr,indexsub)"
                  v-model="salesOrderEdit[indextr][indexsub][1]"
                  :value="salesOrderEdit[indextr][indexsub][1]"
                />
                {{tr.ItemUnit.split(',')[indexsub]}}
              </template>
            </vs-tr>
          </vs-td>
        </vs-tr>
     </template>
     </data-table>
  </div>
</template>
<script>
import VueNumberInput from "@chenfengyuan/vue-number-input";
export default {
  components: {
    VueNumberInput
  },
  props: ["dataItem", "deliveryPlan", "totalQty", "edit"],
  mounted() {
    this.reloadData(this.params)
  },
  watch: {
    dataItem() {
      this.reloadData(this.params)
    }
  },
  
  data: () => ({
    // edit:false,
    params: {
      search: "",
      length: 10,
      page: 1,
      order: "asc",
      sort: "sol.code"
    },
    header: [
      {
        text: "Sales Order Number",
        value: 'sol.code'
      },
      {
        text: "Customer Name",
        value: 'sol.customer_name'
      },
      {
        text: "Quantity Request",
        // value: "name"
      },
      {
        text: "Quantity Reserve",
        // value: "name"
      },
    ],
    responseData:{},
    limits: [5, 10, 25, 50],
    available: 0,
    limitShow: 100,
    drawData: 0,
    isActive: 1,
    checked: [],
    modelCheck: [],
    checkedAll: false,
    search: "",
    detailShow: "core vx-col md:w-1/2 w-full mb-base",
    detailHide: "core vx-col md:w-1/1 w-full mb-base",
    detail: false,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,
    salesOrderEdit: [],
    data: [{ id: 1 }]
  }),
  methods: {
    reloadData(params) {
      console.log("sip")
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/draft/item/lines", {
          params: {
            adjust:'adjust',
            item_code: this.dataItem,
            delivery_plan_id: this.deliveryPlan,
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort
          }
        })
        .then(
          resp => {
            console.log(resp)
            console.log("resp")
            if(resp.code == 200){
              this.responseData = resp.data;
            }
            this.$vs.loading.close();
          }
        );
    },
    test(indextr, indexsub) {
      var available = this.totalQty;
      for (var prop in this.salesOrderEdit) {
        for (var j = 0; j < this.salesOrderEdit[prop].length; j++) {
          // console.log(this.salesOrderEdit[prop][j])
          available =
            parseInt(available) -
            parseInt(this.salesOrderEdit[prop][j][1]) *
              parseInt(this.salesOrderEdit[prop][j][2]);
        }
      }
      this.available = available;
    },
    editData() {
      this.$vs.loading();
      var salesOrder = [];
      // console.log("asd")
      // var temp = 0;
      var available = this.totalQty;
      this.data = this.responseData.items
      for (var i = 0; i < this.data.length; i++) {
        var temp = [];
        var adjust = this.data[i].Quantity.split(",");
        var uom = this.data[i].AmountUom.split(",");
        var splitId = this.data[i].StringID.split(",");
        for (var prop in adjust) {
          available = parseInt(available) - parseInt(uom[prop]);
          temp.push([parseInt(splitId[prop]), adjust[prop], uom[prop] / adjust[prop]]);
        }
        console.log("ASd");
        salesOrder.push(temp);
      }
      this.available = available;
      this.salesOrderEdit = salesOrder;
      setTimeout(() => {
        this.edit = true;
        this.$vs.loading.close();
      }, 2000);
    },
    save() {
      this.$vs.loading();
      if (this.available < 0) {
        setTimeout(() => {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Balance must be greater than 0",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }, 1000);
      } else {
        var send = []
        for (var prop in this.salesOrderEdit) {
          for (var j = 0; j < this.salesOrderEdit[prop].length; j++) {
            var person = {id:this.salesOrderEdit[prop][j][0], quantity:this.salesOrderEdit[prop][j][1],general:this.salesOrderEdit[prop][j][2]};
            send.push(person);
          }
        }
        this.$http
          .post("api/v1/allocation-engine/list/delivery-plan/draft/item/update", {
            adjust: send,
            balance: this.available,
            delivery_plan: this.deliveryPlan,
            item_code: this.dataItem,
          })
          .then(resp => {
            this.$vs.notify({
              title: "success",
              text: "Success",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
            console.log(resp);
            this.getData();
            this.$vs.loading.close();
            this.$emit("refresh","")
          });

      }
    },
  }
};
</script>